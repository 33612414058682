import React from 'react';
import createPlatformApp from "./components/Tools/createPlaformApp";
import createPlatformRoute from './components/Tools/createPlatformRoute'
import AppProvider from './context/AppProvider'
import FileUploader from './components/Tools/Commission/FileUploader'
import FileSender from './components/Tools/Commission/FileSender'
import Notification from './components/Tools/Commission/Notification'
import Home from './components/Home';
import home from './components/Home/homePropsValues'
import Page from './components/Page'
import OauthCallback from './components/Oauth/OauthCallback'
import { messages } from './messages'
import FileFetcher from './components/Tools/Commission/FileFetcher'
import FormBuilder from './components/Form/FormBuilder'
import FormModalWrapper from './components/Tools/FormModal'
import { Crumb } from '@cjdev-internal/visual-stack-x/BreadcrumbPath'
import Breadcrumb from './components/Breadcrumb'
import translations from './translation'
import {PlatformDomains} from "@platform/cj-platform-navigation";



const App = () => {

  /* Commission Tool */
  const CommissionHomePage = () => <Home headers={home.headers} cards={home.cards}/>

  const BodyRequestContent = () => <FormBuilder FormContent={FileUploader}/>

  const requestCrumb: Crumb[] = [
    { label: "Home", onClick: () => document.location.href = '/' },
    { label: "Commission Request", onClick: () => document.location.href = '/commission-request' }
  ]
  const resultCrumb: Crumb[] = [
    { label: "Home", onClick: () => document.location.href = '/' },
    { label: "Commission Result", onClick: () => document.location.href = '/commission-result' }
  ]

  const requestBread = () => <Breadcrumb breadcrumbs={requestCrumb}/>
  const responseBread = () => <Breadcrumb breadcrumbs={resultCrumb}/>

  const CommissionRequestPage = () => <Page
    Bread={requestBread}
    title={messages.labelCommissionRequest.defaultMessage}
    BodyContent={BodyRequestContent}
    FooterContent={FileSender}
    PanelContent={Notification}
  />
  const CommissionResultPage = () => <Page
    Bread={responseBread}
    title={messages.labelCommissionResponse.defaultMessage}
    BodyContent={FileFetcher}
    PanelContent={Notification}
  />
  const OauthCallbackPage = () => <OauthCallback />


  const homeRoute = createPlatformRoute("/", {id: "adminTool", defaultMessage: "Admin Tool"}, CommissionHomePage, FormModalWrapper);
  const requestRoute = createPlatformRoute("/commission-request", {id: "commissionRequest", defaultMessage: "Commission Request"}, CommissionRequestPage, FormModalWrapper);
  const resultRoute = createPlatformRoute("/commission-result", {id: "commissionResult", defaultMessage: "Commission Result"}, CommissionResultPage, FormModalWrapper);
  const oauthCallbackRoute = createPlatformRoute("/oauth-callback", {id: "oauthCallback", defaultMessage: "Oauth Callback"}, OauthCallbackPage, FormModalWrapper)
  const routes = [homeRoute, requestRoute, resultRoute, oauthCallbackRoute];
  const platformDomains : PlatformDomains = { members: 'prod' };
  const CommissionTool = () => createPlatformApp(translations, platformDomains, routes);

  const DefaultAdminTool = () => <AppProvider>{<CommissionTool/>}</AppProvider>

  return <DefaultAdminTool/>
}

export default App;