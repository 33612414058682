import React, { useEffect } from 'react'
import { handleOAuthCallback } from '@platform/platform-auth'

export const OauthCallback = () => {
  useEffect(() => {
    (async () => {
      await handleOAuthCallback()
    })()
  }, [])

  return <></>
}

export default OauthCallback;