export const messages = {
  labelTitle: {
    id: "admin-tool-ui.label-tittle",
    defaultMessage: "Admin Tool",
  },
  labelContent: {
    id: "admin-tool-ui.label-content",
    defaultMessage: "Click the button below to browse the commission file.",
  },
  labelCommissionRequest: {
    id: "admin-tool-ui.label-commission-request",
    defaultMessage: "CORRECTION REQUEST.",
  },
  labelCommissionResponse: {
    id: "admin-tool-ui.label-commission-response",
    defaultMessage: "CORRECTION RESULT.",
  },
  labelFileSelected: {
    id: "admin-tool-ui.label-file-selected",
    defaultMessage: "Selected file: ",
  },
  labelFileSend: {
    id: "admin-tool-ui.label-file-send",
    defaultMessage: "SEND",
  },
  labelTableColumnKey: {
    id: "admin-tool-ui.label-table-column-key",
    defaultMessage: "Key",
  },
  labelTableColumnLastModified: {
    id: "admin-tool-ui.label-table-column-lastModified",
    defaultMessage: "Last Modified",
  },
  labelTableColumnUser: {
    id: "admin-tool-ui.label-table-column-user",
    defaultMessage: "User",
  },
  labelTableColumnEmail: {
    id: "admin-tool-ui.label-table-column-email",
    defaultMessage: "Email",
  }
}