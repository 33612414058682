class Commission {
  key: string
  lastModified: string
  user: string
  email: string
  constructor(key: string, lastModified: string, user: string, email: string) {
    this.key = key;
    this.lastModified = lastModified
    this.user = user
    this.email = email
  }
}

export default Commission;