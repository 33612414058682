import React from 'react'
import * as ReactDOMClient from 'react-dom/client';

import { initAuth } from '@platform/platform-auth'
import {authConfig} from './auth'
import App from './App'

initAuth(authConfig()).then(async () => {
  const container = document.getElementById('root');
  const root = ReactDOMClient.createRoot(container!);
  root.render(<App/>)
})
